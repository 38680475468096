body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --base04:    #00212b;
  --base03:    #002b36;
  --base02:    #073642;
  --base01:    #586e75;
  --base00:    #657b83;
  --base0:     #839496;
  --base1:     #93a1a1;
  --base2:     var(--base1)8d5;
  --base3:     #fdf6e3;
  --yellow:    #b58900;
  --orange:    #cb4b16;
  --red:       #dc322f;
  --magenta:   #d33682;
  --violet:    #6c71c4;
  --blue:      #268bd2;
  --cyan:      #2aa198;
  --green:     #859900;
}

.CodeMirror, .graphiql-explorer-root {
  font-size: 1.1em !important;
}

.graphiql-container {
  height: 100vh;
  width: 100vw;
}

input {
  background-color: #002b36;
  background-color: var(--base03);
}

.graphiql-container .CodeMirror-scroll {
  background-color: #002b36;
  background-color: var(--base03);
}

.CodeMirror-gutters {
  border: none;
}

.graphiql-container .history-contents li {
  border: none;
}

.graphiql-container .history-contents > li:hover {
  background-color: #0a0a0a;
}

::-moz-selection {
  background: #444 !important;
}

::selection {
  background: #444 !important;
}

::-moz-selection {
  background: #444 !important;
}

.CodeMirror-selected {
  background: #333 !important;
}

.CodeMirror-focused .CodeMirror-selected {
  background: rgb(44, 88, 132) !important;
}

pre.CodeMirror-line::-moz-selection,
pre.CodeMirror-line > span::-moz-selection,
pre.CodeMirror-line > span > span::-moz-selection {
  background: #b53 !important;
}

pre.CodeMirror-line::selection,
pre.CodeMirror-line > span::selection,
pre.CodeMirror-line > span > span::selection {
  background: #b53 !important;
}

pre.CodeMirror-line:selection {
  background: #b53 !important;
}

.graphiql-container button {
  background: transparent !important;
  color: #93a1a1 !important;
  color: var(--base1) !important;
}

.graphiql-container button:hover {
  background: transparent !important;
  color: #fff !important;
}

.graphiql-container .toolbar-button,
.graphiql-container .execute-button,
.docExplorerShow {
  transition: all 0.2s;
  background: #00212b !important;
  background: var(--base04) !important;
  color: #93a1a1 !important;
  color: var(--base1) !important;
  box-shadow: none;
}

.graphiql-container .toolbar-button:hover,
.graphiql-container .execute-button:hover,
.docExplorerShow {
  transition: all 0.2s;
  background: #111 !important;
  color: #fff !important;
  box-shadow: none;
}

ul.CodeMirror-hints {
  background: #252525;
}

.CodeMirror-hints li {
  color: #93a1a1 !important;
  color: var(--base1) !important;
  border: none;
}

.CodeMirror-hints p,
.CodeMirror-hints .content {
  color: #bbb !important;
}

.CodeMirror-hints li.CodeMirror-hint-active {
  color: #fff !important;
  background: #384;
}

.CodeMirror-linenumber,
.graphiql-container .history-contents button {
  color: #93a1a1;
  color: var(--base1);
}

.graphiql-container .title {
  color: #93a1a1;
  color: var(--base1);
}

.graphiql-container .docExplorerShow,
.graphiql-container .historyShow,
.graphiql-container .doc-explorer {
  background: #444;
  color: #93a1a1;
  color: var(--base1);
}

.graphiql-container .variable-editor-title {
  border-color: #333;
  background: #002b36;
  background: var(--base03);
  color: #93a1a1;
  color: var(--base1);
}

.graphiql-container .topBar,
.graphiql-container .docExplorerShow {
  border-color: #444;
}

.graphiql-container .result-window .CodeMirror-gutters,
.graphiql-container .resultWrap {
  border-color: #555;
}

.graphiql-container .doc-explorer-title-bar,
.graphiql-container .history-title-bar {
  height: 36px;
}

.graphiql-container .doc-explorer-contents,
.graphiql-container .history-contents {
  background-color: #002b36;
  background-color: var(--base03);
  top: 49px;
}

graphiql-operation-title-bar > input {
  border: 1px solid red;
}

.graphiql-container .search-box > input {
  background-color: #666;
  color: #fff;
}

.CodeMirror div.CodeMirror-cursor {
  border-left: 2px solid #93a1a1;
  border-left: 2px solid var(--base1);
}

.CodeMirror div.CodeMirror-selection {
  background-color: #666 !important;
}

.graphiql-container .keyword {
  color: #cb4b16;
  color: var(--orange);
}

.graphiql-container .field-name {
  color: #268bd2;
  color: var(--blue);
}

.graphiql-container .doc-explorer-back {
  color: #fff;
}

.graphiql-container .arg-name {
  color: #fff;
}

.graphiql-container .type-name {
  color: #b58900;
  color: var(--yellow);
}

.graphiql-container .doc-explorer-back:before,
button.docExplorerShow:before {
  border-left: 2px solid #93a1a1 !important;
  border-left: 2px solid var(--base1) !important;
  border-top: 2px solid #93a1a1 !important;
  border-top: 2px solid var(--base1) !important;
}

.cm-keyword,
div[style*="color: rgb(177, 26, 4);"] {
  color: #b58900 !important;
  color: var(--yellow) !important;
}

.cm-def,
div[style*="color: rgb(177, 26, 4);"] input {
  color: #d33682 !important;
  color: var(--magenta) !important;
}

.cm-property,
span[style="color: rgb(31, 97, 160);"],
.graphiql-container .history-contents input[style*="color: var(--magenta);"],
.graphiql-container .history-contents span[style*="color: var(--magenta);"] {
  color: #859900 !important;
  color: var(--green) !important;
}

.cm-attribute,
span[style="color: rgb(139, 43, 185);"] {
  color: #268bd2 !important;
  color: var(--blue) !important;
}

.cm-string,
.cm-string-2 {
  color: #2aa198;
  color: var(--cyan);
}

.cm-builtin {
  color: #fff;
}

.cm-qualifier {
  color: #1dd761;
}

.graphiql-container div.CodeMirror span.CodeMirror-matchingbracket,
.cm-punctuation,
.cm-ws,
.cm-operator,
.CodeMirror pre {
  color: #999;
}

.graphiql-container .execute-options,
select {
  background-color: #444 !important;
  color: #93a1a1 !important;
  color: var(--base1) !important;
  border: none;
}

.graphiql-container .execute-options > li.selected,
.graphiql-container .toolbar-menu-items > li.hover,
.graphiql-container .toolbar-menu-items > li:active,
.graphiql-container .toolbar-menu-items > li:hover,
.graphiql-container .toolbar-select-options > li.hover,
.graphiql-container .toolbar-select-options > li:active,
.graphiql-container .toolbar-select-options > li:hover,
.graphiql-container .history-contents > p:hover,
.graphiql-container .history-contents > p:active {
  background: #6f6f6f;
  color: #fff;
}

.graphiql-container .doc-explorer-title-bar,
.graphiql-container .history-title-bar {
  background: #002b36;
  background: var(--base03);
  color: #93a1a1;
  color: var(--base1);
}

.graphiql-container .doc-explorer-contents,
.graphiql-container .history-contents {
  background-color: #00212b;
  background-color: var(--base04);
  top: 48px;
  color: #93a1a1;
  color: var(--base1);
}

.graphiql-container .doc-explorer-contents,
.graphiql-container .history-contents {
  border-top: 1px solid #555;
  border: none;
}

.graphiql-container .history-contents p {
  color: #93a1a1;
  color: var(--base1);
}

/* TO RESTORE THE PRETTIFY BUTTON, REMOVE THIS NEXT ITEM */
.graphiql-container .toolbar-button:first-child {
  display: none;
}

.graphiql-container .search-box > input {
  background-color: #002b36 !important;
  background-color: var(--base03) !important;
}

.graphiql-container .search-box {
  border: none;
}

.graphiql-explorer-root > div {
  /*     border: none!important; */
  border-color: #333 !important;
  padding-top: 10px;
}

.graphiql-container .topBar,
.CodeMirror-gutters div,
button.docExplorerShow,
.doc-explorer-back {
  background: #002b36 !important;
  background: var(--base03) !important;
  border: none !important;
}

.doc-explorer-back,
button.docExplorerShow {
  background: #002b36 !important;
  background: var(--base03) !important;
  border: none !important;
}

button.docExplorerShow:hover,
button.doc-explorer-back:hover {
  background: #002b36 !important;
  background: var(--base03) !important;
}

.doc-explorer-back {
  transition: all 0.2s;
  border: none !important;
}

button.doc-explorer-back:hover {
  transition: all 0.2s;
}

/* ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
   Webkit-only here (Chrome, Opera, Steam)
   ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- */
::-webkit-scrollbar {
  background-color: transparent !important;
  height: 12px !important;
  width: 12px !important;
}

::-webkit-scrollbar-button {
  display: none !important;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar-track:horizontal {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

::-webkit-scrollbar-track:vertical {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent !important;
  border-radius: 0px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #333 !important;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3b3b3b !important;
}

